import { VideoModalComponent } from './component/video-modal/video-modal.component';
import { AfterViewInit, Component } from '@angular/core';
import { ChatService } from './services/chat-service.service';
import { NzModalService } from 'ng-zorro-antd/modal';
// import { fromEvent, map, distinctUntilChanged } from 'rxjs';
// const scroll$ = fromEvent(window, 'scroll').pipe(
//   map(() => window.scrollY),
//   distinctUntilChanged()
// );
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'JLMConsulting';
  videoHtml: any;
  activeSection: string | null = null;

  constructor(private modalService: NzModalService){}

  openVideoPopup() {
    // const youtubeVideoUrl = 'https://www.youtube.com/embed/6htlF8lGwAg';
    const youtubeVideoUrl = 'https://www.youtube.com/embed/JVyAcjsgoVE';
    const modalRef = this.modalService.create({
      nzContent: VideoModalComponent,
      nzComponentParams: {
        videoUrl: youtubeVideoUrl
      },
      nzWidth: '80%',
      nzBodyStyle: {
        height: '80vh',
        background: 'rgba(0, 0, 0, 0.3)'
      },
      nzWrapClassName: 'modal-transparent',
      nzCloseIcon: '<i class="icon-close" style="color: #ffffff;"></i>',
      nzFooter: null
    });

    modalRef.afterOpen.subscribe(() => {
      // Do something after the modal is opened
    });

    modalRef.afterClose.subscribe(() => {
      // Do something after the modal is closed
    });
  }
  scroll(top: number) {
    
    window.scrollTo({
      top,
      behavior: 'smooth' // You can change this to 'auto' if you prefer an instant scroll
    });
  }
  isActive(sectionId: string): boolean {
    return this.activeSection === sectionId;
  }

  openModal(num:number): void {
    const nzContent = num==1
    ?`JLM Consulting's Multi-Cloud Solutions specializes in delivering top-notch cloud architecture services. With expertise in designing and implementing tailored multi-cloud solutions, we ensure seamless integration, scalability, and security across various cloud platforms.

    As a Microsoft Azure partner, we unlock the power of Azure cloud services to drive innovation and digital transformation. Our Azure-certified architects leverage Azure's capabilities, including virtual machines, storage, AI, and machine learning, to enhance productivity and enable scalable solutions.
    
    As an AWS consulting partner, we harness the scalability and flexibility of Amazon Web Services (AWS) for your cloud initiatives. Our AWS-certified architects design cost-effective and reliable solutions, utilizing services like EC2, S3, Lambda, and DynamoDB.
    
    Additionally, our multi-cloud strategy enables the integration of Azure and AWS, combining the benefits of both platforms. With our expertise, you can achieve flexibility, redundancy, and cost optimization.
    
    Partner with JLM Consulting to unlock the full potential of cloud architecture and multi-cloud solutions. Let us design, optimize, and implement your cloud strategy for enhanced scalability, agility, and business success.`:
    `At JLM Consulting, we go beyond basic security measures to provide robust protection for your data and applications. Our dedicated team of security experts stays up to date with the latest threats and vulnerabilities to implement advanced security protocols.

    Online security is a top priority for us. We employ industry-leading encryption techniques and secure authentication mechanisms to ensure that your data is shielded from unauthorized access. By implementing multi-factor authentication and role-based access controls, we minimize the risk of data breaches and unauthorized system access.
    
    In addition to implementing security measures, we also prioritize proactive monitoring and reporting of cloud security systems. Our team utilizes sophisticated tools and technologies to continuously monitor your cloud environment for any suspicious activities or anomalies. We provide real-time alerts and detailed reports to keep you informed about the security status of your systems.
    
    Furthermore, we understand the importance of compliance and regulatory requirements. We assist you in meeting industry-specific security standards and ensure that your cloud infrastructure adheres to necessary regulations. Our team conducts regular audits and assessments to validate the effectiveness of your security controls and address any vulnerabilities.
    
    With JLM Consulting, you can have peace of mind knowing that your data and applications are protected by robust security measures. We are committed to providing comprehensive security solutions that safeguard your valuable assets and mitigate risks in the ever-evolving digital landscape.`
    this.modalService.create({
      nzTitle: '<span style="color: white;">Read More</span>',
      nzContent,
      nzFooter: null,
      nzClassName: 'custom-modal',
    });
  } 

  ngAfterViewInit(): void {
    // scroll$.subscribe(console.log);
    const loader = document.querySelector('.gtco-loader');
  
    const stopLoader = async () =>{
      loader?.parentNode?.removeChild(loader);
    }
    setTimeout(stopLoader, 1500);
  }
  openPopup(targetURL: string) {
    const windowFeatures = 'width=600,height=400,resizable=yes';
    window.open(targetURL, 'newwindow', windowFeatures);
  }
}
