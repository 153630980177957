import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AppPersist {
    private localStore: LocalForage;
    private readonly secretKey: string;
    constructor() {
        this.secretKey = `jlm-cms-prd`;
        this.localStore = localForage.createInstance({
            // driver: localForage.WEBSQL,
            name: this.encrypt('storeData')
        })
    }
 
    async getAppData(): Promise<any> {
        const encryptedData = await this.localStore.getItem(this.encrypt('storeData'));
        if (!encryptedData) {
            return null;
        }
        const decryptedData = this.decrypt(encryptedData);
        return JSON.parse(decryptedData); 
    }

    async getDataStore(store: any) {
        const encryptedData = await this.localStore.getItem(this.encrypt('storeData'));
        if (!encryptedData) {
            return store;
        }
        const decryptedData = this.decrypt(encryptedData);
        const storeData = JSON.parse(decryptedData);

        store = _.assign(store, _.pick(storeData, _.keys(store)));
        _.mapValues(_.pick(store, _.keys(storeData)), async (value, key) => await this.setDataStore(key, value));
        return store;
    }

    async setDataStore(key: string, value: any): Promise<void> {
        const encryptedData = await this.localStore.getItem(this.encrypt('storeData'));
        const storeData = encryptedData ? JSON.parse(this.decrypt(encryptedData)) : {};

        storeData[key] = value;

        const encryptedStoreData = this.encrypt(JSON.stringify(storeData));
        await this.localStore.setItem(this.encrypt('storeData'), encryptedStoreData);
    }

    async clearDataStore(): Promise<void> {
        await this.localStore.removeItem(this.encrypt('storeData'));
        return await this.localStore.clear()
        .then(cleared => console.log({cleared: _.isUndefined(cleared)}))
        .catch(error => console.log({error}))
    }

    private encrypt(data: any): string {
        let result = '';
        for (let i = 0; i < data.length; i++) {
            result += String.fromCharCode(data.charCodeAt(i) ^ this.secretKey.charCodeAt(i % this.secretKey.length));
        }
        return result;
    }

    private decrypt(data: any): string {
        let result = '';
        for (let i = 0; i < data.length; i++) {
            result += String.fromCharCode(data.charCodeAt(i) ^ this.secretKey.charCodeAt(i % this.secretKey.length));
        }
        return result;
    }
}
