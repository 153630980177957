import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from './https.service';
import * as SBD from 'sbd';
import * as _ from 'lodash';
export const runScript = (text: string): string =>
  `Your are a Consultant/CEO named Jason Mullings, receiving messages from Your website called https://jlmconsulting.services\n
  You are a Specialist in Cloud IT strategy, digital transformation, project management, and technology solutions. \n
  Your standard office hours in the Philippines are from 9:00 AM to 6:00 PM. \n
  Your Agency rates are $500.00/day. \n
  Please return short Your answers to the prompt in less than 30 words, all related to SaaS and Cloud solutions.\n
  Never give out contact information, always refer to the current site details!\n
  Do NOT ask questions!\n
  Do NOT repeat answers!\n
  The User will first greet you with: ${text}`
export class Message {
  constructor(public author: string, public content: string) { }
}
// Create a new service class
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private sessionId: string = "";
  public conversation = new Subject<Message[]>();
  private readonly endpoint = "https://api.jlmconsulting.services";
  messageMap = {
    "Hi": "Hello",
    "Who are you": "My name is Test Sat Bot",
    "What is your role": "Just guide for the user",
    "defaultmsg": "I can't understand your text. Can you please repeat"
  }
 
  constructor(private httpService: HttpService) {}

  findBye(text: string) {
    // Use a case-insensitive regular expression to match the word "bye"
    const regex = /\bbye\b/i;
    return regex.test(text);
  }
  async getBotAnswer(msg: string) {
    const userMessage = new Message('user', msg);
    this.conversation.next([userMessage]);
    const botMessage = new Message('bot', await this.getBotMessage(msg));
    _.map(this.separateSentences(botMessage.content), (content: string)=>  this.conversation.next([{author: botMessage.author,content}]))
   ;
  }
  separateSentences(paragraph: string): string[] {
    return SBD.sentences(paragraph);
  }
  async getBotMessage(prompt: string) {
    if (!_.size(this.sessionId)) {
      return await this.postToChatGPT(runScript(prompt))
    }
    return await this.getChatGPT(prompt)
  }

  async getChatGPT(prompt: string) {
    const resp = await this.httpService.get(this.endpoint + '/chatGPT', { prompt, sessionId: this.sessionId }).toPromise();
    if (_.has(resp, "message") && resp.message.length) {
      if (this.findBye(resp.message)) {
        this.sessionId = "";
      }
      return resp.message;
    }
    return this.messageMap['defaultmsg']
  }

  async postToChatGPT(prompt: string) {
    const resp = await this.httpService.addResponse(this.endpoint, '/chatGPT', { prompt }).toPromise();
    if (_.has(resp, "message") && resp.message.length) {
      this.sessionId = resp.sessionId;
      return resp.message;
    }
    return this.messageMap['defaultmsg']
  }
}