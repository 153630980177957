<div class="chatbotPopup" *ngIf="show">
  <div class="card">
    <div class="card-header">
      Chatbot
      <button class="btn btn-outline-warning close-button" (click)="show = !show">Close</button>
    </div>
    <div class="card-body">
      <ng-container *ngFor="let message of messages">
        <div class="message" [ngClass]="{ from: message.author === 'bot', to: message.author === 'user' }">
          <div class="icon-container" *ngIf="message.author === 'bot'">
            <i nz-icon nzType="robot" nzTheme="outline" style="font-size: 24px;"></i>
          </div>
          <div class="message-content">{{ message.content }}</div>
          <div class="icon-container" *ngIf="message.author === 'user'">
            <i nz-icon nzType="user" nzTheme="outline" style="font-size: 24px;"></i>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-sm-9">
          <div class="form-group mb-0">
            <input #search class="form-control" nz-input [(ngModel)]="val" (keyup.enter)="sendMessage(search.value)">
          </div>
        </div>
        <div class="col-sm-3">
          <button class="btn btn-primary" nz-button nzSize="large" (click)="sendMessage(val)">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="chatIcon" (click)="show = !show">
  <i nz-icon nzType="robot" nzTheme="outline" style="font-size: 24px;"></i>
</div>
