import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ChatService } from './services/chat-service.service';
// import { NgOpenaiModule } from 'ng-openai';
import { ChatBoxComponent } from './component/chat-box/chat-box.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CloudOutline } from '@ant-design/icons-angular/icons';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_GB, en_US } from 'ng-zorro-antd/i18n';
import * as AllIcons from '@ant-design/icons-angular/icons';

import en from '@angular/common/locales/en';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IconDefinition } from '@ant-design/icons-angular';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { VideoModalComponent } from './component/video-modal/video-modal.component';
import { ContactFormComponent } from './component/contact-form/contact-form.component';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { AppPersist } from './services/persist.service';
import { HttpService } from './services/https.service';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent,
    ChatBoxComponent,
    ContactFormComponent,
    VideoModalComponent
  ],
  imports: [
    BrowserModule,
    CommonModule, 
    FormsModule,
    NzFormModule,
    NzAffixModule,
    NzModalModule, 
    NzIconModule.forRoot([CloudOutline]),
    NzBackTopModule,
    ReactiveFormsModule,
    NzAnchorModule,
    NzMessageModule,
    // NgOpenaiModule.forRoot('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'),
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [AppPersist, ChatService, HttpService, NzMessageService,
    { provide: NZ_I18N, useValue: en_GB }, 
    { provide: NZ_I18N, useValue: en_US }, 
    { provide: NZ_ICONS, useValue: icons }],
  bootstrap: [AppComponent]
})
export class AppModule { }
