import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpService } from 'src/app/services/https.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {
  contactForm!: FormGroup;
  private readonly endpoint = "https://api.jlmconsulting.services";
  constructor(private formBuilder: FormBuilder, private message: NzMessageService,private httpService: HttpService) {
    this.createForm();
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  async submitForm(): Promise<any> {
    if (this.contactForm.valid) {
      // Send the form data to the server or perform any desired actions
      console.log('Form submitted:', this.contactForm.value);
      const payload = {
        "subject": "Contact Us!",
        ...this.contactForm.value
    }
      const resp = await this.httpService.addResponse(this.endpoint, '/postToEmail', payload).toPromise();
      if(resp){
        this.contactForm.reset();
        return this.message.success('Form submitted successfully!');
      }
       
      this.contactForm.reset();
      return this.message.error('An error occurred, please try again!');
     
    } else {
      // Mark all form fields as touched to display validation errors
      this.markAllFieldsAsTouched();
    }

  }

  markAllFieldsAsTouched() {
    Object.keys(this.contactForm.controls).forEach(field => {
      const control = this.contactForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
}
