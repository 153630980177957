
  <section id="contact" class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center heading">
                <h2 class="section-title">Contact Us</h2>
                <p class="sub">Get in touch with us</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <form [formGroup]="contactForm" (ngSubmit)="submitForm()" class="contact-form">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" formControlName="name" placeholder="Your Name" />
                                <div
                                  *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched"
                                  class="error-message"
                                >
                                  Name is required.
                                </div>
                              </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input nz-input class="form-control" formControlName="email" placeholder="Your Email" />
                                <div
                                  *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
                                  class="error-message"
                                >
                                  Email is required and must be a valid email address.
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea nz-input class="form-control" formControlName="message" rows="5" placeholder="Your Message"></textarea>
                                <div
                                  *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched"
                                  class="error-message"
                                >
                                  Message is required.
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button type="submit" class="btn btn-primary btn-lg" nz-button nzSize="large" [disabled]="!contactForm.valid">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>