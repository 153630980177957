import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ChatService, Message } from 'src/app/services/chat-service.service';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {
  show = false;
  messages: Message[] = [];
  value!: string;
  val: any;

  constructor(
    public chatService: ChatService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.chatService.conversation.subscribe((val) => {
      this.messages = this.messages.concat(val);
      this.scrollToBottom();
    });
    this.sendMessage("Hello JLM Consulting!")
  }

  toggleChat() {
    this.show = !this.show;
  }

  sendMessage(val: string) {
    this.chatService.getBotAnswer(val);
    this.value = '';
    this.val = '';
  }

  scrollToBottom() {
    setTimeout(() => {
      const chatBody = this.elementRef.nativeElement.querySelector('.card-body');
      chatBody.scrollTop = chatBody.scrollHeight;
    }, 0);
  }
}
